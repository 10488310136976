<template>
  <main class="main news">
    <loading-overlay :active="filtering" :is-full-page="fullPage" :loader="loader" />

    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" class="my-5">
      <section-title
        v-text="pageData.acf.title"
        v-if="pageData && pageData.acf && pageData.acf.title"
        >news</section-title
      >
    </b-container>

    <b-container fluid="xl" class="px-0" v-if="filters">
      <form-category-market
        :firstFilter="filters[$route.meta.category_taxonomy]"
        :secondFilter="filters.market_category"
        :listLength="articlesList.length"
        :allowOrderBy="true"
        :allowPagination="true"
        @chooseFirstFilter="chooseFirstFilter"
        @chooseSecondFilter="chooseSecondFilter"
        @sortList="sortList"
        @perPage="perPage"
      />
    </b-container>

    <b-container fluid="xl" class="px-0">
      <news-list-item
        v-for="(item, index) in articlesList"
        :article="item"
        :slug="$route.meta.slug_item"
        :anchor="pageData.acf.read_more_button.anchor"
      >
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="bg-silver" />
        </b-container>
      </news-list-item>

      <b-container
        fluid="xl"
        class="px-0 my-3 text-center"
        v-if="see_more && page < filters.total_pages"
      >
        <a @click="seeMore" class="px-0 my-5 text-uppercase more">
          <span>see more</span>
          <br />
          <img src="/images/icons/common/icon-down-arrow.svg" alt="" />
        </a>
      </b-container>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import NewsListItem from "../components/NewsListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    NewsListItem,
    Divider,
  },
  data() {
    return {
      filtering: false,
      fullPage: true,
      isLoading: true,
      loader: "bars",
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      pageData: [],
      hasData: false,
      articlesList: [],
      news_category: "",
      market_category: "",
      page: 1,
      filters: {},
      see_more: true,
      sort: "desc",
      per_page: 5,
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  created() {
    this.getPageDataBySlug();
    this.getNews();
  },
  watch: {
    $route(to, from) {
      this.getPageDataBySlug();
      this.getNews();
    },
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("custom-pages/" + this.$route.meta.slug_page)
        .then((response) => {
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNews() {
      this.filtering = true;
      // console.log(`${this.$route.meta.endpoint_items}?${this.$route.meta.category_taxonomy}=${this.news_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`);
      axios
        .get(
          `${this.$route.meta.endpoint_items}?${this.$route.meta.category_taxonomy}=${this.news_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}&sort=${this.sort}`
        )
        .then((response) => {
          // console.log("news", response);
          let length = response.data.length - 1;
          this.filters = response.data[length];
          // console.log("filters", this.filters);

          let object = response.data;
          object.pop();
          this.articlesList = object;
          this.filtering = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseFirstFilter(item) {
      this.page = 1;
      this.news_category = item;
      this.getNews();
    },
    chooseSecondFilter(item) {
      this.page = 1;
      this.market_category = item;
      this.getNews();
    },
    sortList(item) {
      this.page = 1;
      this.sort = item;
      this.getNews();
    },
    perPage(item) {
      this.page = 1;
      this.per_page = item;
      this.getNews();
    },
    seeMore() {
      this.filtering = true;
      this.page++;
      //console.log('current page', this.page);
      axios
        .get(
          `${this.$route.meta.endpoint_items}?${this.$route.meta.category_taxonomy}=${this.news_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}&sort=${this.sort}`
        )
        .then((response) => {
          // console.log("seemore", response);
          let object = response.data;
          object.pop();

          // console.log(object.length);

          if (object.length) {
            this.articlesList.push(...object);
          } else {
            this.see_more = false;
          }

          this.filtering = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
