<template>
  <div>
    <b-row class="item">
      <b-col md="4" class="px-lg-4 px-md-4 col-12 order-md-2 mb-3 mb-md-0">
        <img
          v-if="article.acf.short_description_image"
          :src="
            backendResourceDomain +
            article.acf.short_description_image.sizes.square_large
          "
          alt=""
          class="img-fluid"
        />
      </b-col>
      <b-col md="8" class="">
        <div class="meta d-flex px-3">
          <span class="date">{{ article.post_date }}</span>
          <badges
            :categories="article.categories"
            :markets="article.markets"
          ></badges>
        </div>
        <div class="content d-flex flex-column px-3">
          <router-link :to="'/' + slug + '/' + article.id">
            <section-title class="text-left py-4">{{
              article.title
            }}</section-title>
          </router-link>
          <p
            class="text-small excerpt"
            v-html="article.acf.short_description"
          ></p>
          <router-link :to="'/' + slug + '/' + article.id">
            <b-button
              variant="primary"
              class="
                w-md-100
                p-3
                px-5
                mt-4
                mx-auto
                ml-md-5
                text-small text-uppercase
                d-block
              "
            >
              {{ anchor }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <slot></slot>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";
import Badges from "./Badges";

export default {
  components: { SectionTitle, Badges },
  data() {
    return {
      backendResourceDomain: "",
    };
  },
  props: {
    article: {
      required: true,
    },
    slug: {
      required: true,
    },
    anchor: {
      type: String,
      required: true,
    },
  },
  methods: {
    badgeClassExtraction(item) {
      return item.map((cat) => "badge-" + cat.slug).join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";

.item {
  .meta {
    font-family: "NeoSansPro Medium";
    font-style: normal;
    .date {
      font-family: "NeoSansPro Light";
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .btn {
    max-width: 300px;
  }
}
</style>
